/* eslint-disable sonarjs/cognitive-complexity */
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import type { Document } from '@contentful/rich-text-types';
import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import type { ReactNode } from 'react';
import React from 'react';
import { createContext } from 'use-context-selector';
import { storeLocale } from '@/root/constants';
import type { ImageType } from 'components/plpContext/types/storePLP';
import { useAccountSettingsQuery } from 'lib/contentful/__generated__/AccountSettings';
import { contentfulQueryDataSource } from 'lib/contentful/dataSources';
import type { StoreSeo } from 'utils/types/storeTypes';

export type AccountContentContextType = {
  loading: boolean;
  login: {
    seo?: StoreSeo;
    image?: ImageType;
  };
  register: {
    seo?: StoreSeo;
    customerImage?: ImageType;
    professionalImage?: ImageType;
    wellaBrandsUrl?: string;
    termsAndConditionsUrl?: string;
    successRedirectUrl?: {
      pro: string;
      consumer: string;
    };
  };
  activate: {
    seo?: StoreSeo;
    image?: ImageType;
  };
  reset: {
    seo?: StoreSeo;
    image?: ImageType;
  };
  overview: {
    seo?: StoreSeo;
  };
  addresses: {
    seo?: StoreSeo;
  };
  orders: {
    seo?: StoreSeo;
  };
  order: {
    seo?: StoreSeo;
  };
  wishlist: {
    seo?: StoreSeo;
  };
  pendingProAccountMsg?: ReactNode;
};

export const AccountContentContext = createContext<AccountContentContextType>({
  loading: false,
  login: {},
  register: {},
  activate: {},
  reset: {},
  overview: {},
  addresses: {},
  order: {},
  orders: {},
  wishlist: {},
});

export const AccountContentProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { locale: routerLocale, query } = useRouter();

  const locale = storeLocale(routerLocale);
  const variables = {
    locale,
  };

  const preview = typeof query.preview === 'string' ? !!query.preview : false;

  const { data, isLoading } = useQuery(
    useAccountSettingsQuery.getKey(variables),
    useAccountSettingsQuery.fetcher(
      contentfulQueryDataSource({ byPassCache: preview }),
      variables
    )
  );

  const contextData: AccountContentContextType = {
    login: {},
    register: {},
    activate: {},
    reset: {},
    overview: {},
    addresses: {},
    order: {},
    orders: {},
    wishlist: {},
    loading: isLoading,
  };

  const formatImage = (
    imageData:
      | {
          __typename?: 'Asset' | undefined;
          title?: string | null | undefined;
          url?: string | null | undefined;
          width?: number | null | undefined;
          height?: number | null | undefined;
        }
      | null
      | undefined
  ) => {
    if (!imageData) return;

    return {
      width: imageData?.width ?? 0,
      height: imageData?.height ?? 0,
      url: imageData?.url ?? '',
      title: imageData?.title ?? '',
    };
  };

  const accountContent = data?.accountSettingsCollection?.items[0];

  if (accountContent) {
    contextData.login = {
      seo: {
        title: accountContent.loginSeoTitle ?? 'Login',
        description: accountContent.loginSeoDescription ?? '',
      },
      image: formatImage(accountContent.loginImage),
    };

    contextData.register = {
      seo: {
        title: accountContent.registerSeoTitle ?? 'Register',
        description: accountContent.registerSeoDescription ?? '',
      },
      wellaBrandsUrl: accountContent.registerWellaBrandsUrl ?? '',
      termsAndConditionsUrl: accountContent.registerTermsAndConditionsUrl ?? '',
      customerImage: formatImage(accountContent.registerCustomerViewImage),
      professionalImage: formatImage(
        accountContent.registerProfessionalViewImage
      ),
      successRedirectUrl: {
        consumer: accountContent.registerSuccessUrlConsumer ?? '',
        pro: accountContent.registerSuccessUrlProfessionals ?? '',
      },
    };

    contextData.reset = {
      seo: {
        title: accountContent.resetSeoTitle ?? 'Reset Password',
        description: accountContent.resetSeoDescription ?? '',
      },
      image: formatImage(accountContent.resetImage),
    };

    contextData.activate = {
      seo: {
        title: accountContent.activateSeoTitle ?? 'Activate Account',
        description: accountContent.activateSeoDescription ?? '',
      },
      image: formatImage(accountContent.activateImage),
    };

    contextData.overview = {
      seo: {
        title: accountContent.accountOverviewSeoTitle ?? 'Account Overview',
        description: accountContent.accountOverviewSeoDescription ?? '',
      },
    };

    contextData.addresses = {
      seo: {
        title: accountContent.addressesSeoTitle ?? 'My Addresses',
        description: accountContent.addressesSeoDescription ?? '',
      },
    };

    contextData.orders = {
      seo: {
        title: accountContent.ordersListSeoTitle ?? 'My Orders',
        description: accountContent.ordersListSeoDescription ?? '',
      },
    };

    contextData.order = {
      seo: {
        title: accountContent.orderPageSeoTitle ?? 'Order Details',
        description: accountContent.orderPageSeoDescription ?? '',
      },
    };

    contextData.wishlist = {
      seo: {
        title: accountContent.wishlistSeoTitle ?? 'My Wishlist',
        description: accountContent.wishlistSeoDescription ?? '',
      },
    };
  }

  const pendingProAccountMsgData = (accountContent?.pendingProAccountMsg
    ?.json ?? '') as Document;

  contextData.pendingProAccountMsg = documentToReactComponents(
    pendingProAccountMsgData
  );

  return (
    <AccountContentContext.Provider value={contextData}>
      {children}
    </AccountContentContext.Provider>
  );
};
