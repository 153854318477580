import clsx from 'clsx';
import Script from 'next/script';
import { useCallback, useRef } from 'react';
import styles from 'components/captcha/Captcha.module.scss';

declare global {
  interface Window {
    grecaptcha?: {
      render: (id: string, options: Record<string, string>) => void;
      reset: (widgetId?: string) => void;
      getResponse: (widgetId?: string) => void;
    };
    onloadCallback: () => void;
    onResponseCallback: (token: string) => void;
    onExpireCallback: () => void;
    onErrorCallback: () => void;
  }
}

/**
 * Check that ReCAPTCHA is enabled for the site
 * - Checks that the `NEXT_PUBLIC_RECAPTCHA_ENABLED` environment variable is set
 *   to 'true'
 */
export const isRecaptchaEnabled = () => {
  return process.env.NEXT_PUBLIC_RECAPTCHA_ENABLED === 'true';
};

const siteKey = process.env.NEXT_PUBLIC_RECAPTCHA_KEY ?? '';
const recaptchaEnabled = isRecaptchaEnabled();

export const Captcha = ({
  responseCallback = console.log,
  expireCallback = console.log,
  errorCallback = console.log,
  renderCallback = console.log,
  error,
  className,
}: {
  responseCallback?: (token: string) => void;
  expireCallback?: () => void;
  errorCallback?: () => void;
  renderCallback?: () => void;
  error: Error | null;
  className?: string;
}) => {
  const captchaRef = useRef(null);

  const onLoad = useCallback(() => {
    if (!window?.grecaptcha?.render) return;

    window.grecaptcha.render('opiReCaptcha', {
      sitekey: siteKey,
      callback: 'onResponseCallback',
      'expired-callback': 'onExpireCallback',
      'error-callback': 'onErrorCallback',
    });

    // Reset captcha and it's error on render.
    renderCallback();
  }, [renderCallback]);

  const onResponse = useCallback(
    (token: string) => {
      responseCallback(token);
    },
    [responseCallback]
  );

  const onExpire = useCallback(() => {
    expireCallback();
  }, [expireCallback]);

  const onError = useCallback(() => {
    errorCallback();
  }, [errorCallback]);

  const setCallbacks = useCallback(() => {
    window.onloadCallback = onLoad;
    window.onExpireCallback = onExpire;
    window.onResponseCallback = onResponse;
    window.onErrorCallback = onError;
  }, [onError, onExpire, onLoad, onResponse]);

  if (!siteKey || !recaptchaEnabled) {
    return <div className={clsx(styles.captcha__wrapper, className)}></div>;
  }

  return (
    <div className={clsx(styles.captcha__wrapper, className)}>
      <div id="opiReCaptcha" className={styles.captcha} ref={captchaRef}></div>
      {error && (
        <p
          className={clsx(
            styles.captcha__error,
            'text-body-small-desktop text-body-small-mobile'
          )}
        >
          {error.message}
        </p>
      )}
      <Script
        src="https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit"
        onLoad={() => {
          setCallbacks();
        }}
        onReady={() => {
          setCallbacks();
          onLoad();
        }}
      />
    </div>
  );
};
