/* eslint-disable sonarjs/cognitive-complexity */
import { Trans, t } from '@lingui/macro';
import AddressFormatter from '@shopify/address';
import { useQuery } from '@tanstack/react-query';
import { Button } from '@we-make-websites/ui-lib';
import {
  Shimmer,
  toBase64,
} from '@we-make-websites/ui-lib/src/components/utility/imageShimmer/ImageShimmer';
import clsx from 'clsx';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { postcodeValidator } from 'postcode-validator';
import React, { useState } from 'react';
import type { DragEvent, ChangeEvent } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { useEffectOnceWhen } from 'rooks';
import { useContext } from 'use-context-selector';
import {
  GB_LOCALE_COUNTRIES,
  PRO_TYPES,
  SALON_TYPES,
  storeLocale,
  WELLASTORE_PROFESSIONAL_URL,
  isChLocale as checkIsChLocale,
  CH_LOCALE_COUNTRIES,
  CH_LOCALE_PREFERRED_LANGUAGE,
  FR_LOCALE_COUNTRIES,
  hasLocaleSupportForProUsers,
  ES_LOCALE_COUNTRIES,
  DE_LOCALE_COUNTRIES,
  IT_LOCALE_COUNTRIES,
  JP_LOCALE_COUNTRIES,
  localesWithoutInterestFormForProUsers,
  localesWithoutProTypeSelectForProUsers,
} from '@/root/constants';
import type { StoreLocale } from '@/root/constants';
import { Breadcrumbs } from 'components/breadcrumbs/Breadcrumbs';
import { Captcha, isRecaptchaEnabled } from 'components/captcha/Captcha';
import {
  FormField,
  FormSelectField,
  FormCheckboxField,
  FormRadioButton,
  FormPasswordField,
} from 'components/signUpForm/FormFields';
import { SignUpFormContext } from 'components/signUpForm/SignUpFormContext';
import IconFile from 'icons/misc/file.svg';
import { getCountryCodeFromLocale } from 'utils/localization';
import { AccountContentContext } from '../account/accountContentContext/AccountContentContext';
import styles from './SignUpForm.module.scss';

export interface SignUpFormInput {
  pro: boolean;
  existingUKCustomer: boolean;
  customerNumber: string;
  firstName: string;
  lastName: string;
  email: string;
  confirmEmail: string;
  password: string;
  confirmPassword: string;
  dateOfBirth: string;
  acceptMarketing: 'true' | 'false';
  acceptTerms: boolean;
  rememberMe: boolean;
  phone: string;
  vatNumber: string;
  ltdNumber: string;
  licenseNumber: string;
  professionalType: string;
  preferredLanguage?: string;
  // Contact Info Address
  salon: string;
  address: string;
  city: string;
  country: string;
  state: string;
  zip: string;
  // Shipping Address
  shipAddressIdentical: boolean;
  shipAddress: string;
  shipCity: string;
  shipCountry: string;
  shipState: string;
  shipZip: string;
  // Billing Address
  billAddressIdentical: boolean;
  billAddress: string;
  billCity: string;
  billCountry: string;
  billState: string;
  billZip: string;
  // UK Qualifications
  files: File[] | null;
  publicLiabilityInsurance: boolean;
  studentProofEnrollment: boolean;
  // US Interests
  interest1: boolean;
  interest2: boolean;
  interest3: boolean;
  interest4: boolean;
  interest5: boolean;
  interest6: boolean;
  interest7: boolean;
  // Captcha: UK required
  captcha: string;
  locale: StoreLocale;
}

const recaptchaSiteKey = process.env.NEXT_PUBLIC_RECAPTCHA_KEY ?? '';

export const getPreferredLanguages = (isChLocale: boolean) => {
  if (!isChLocale) return [];
  // Return CH list of languages.
  return CH_LOCALE_PREFERRED_LANGUAGE.map((lang) => ({
    ...lang,
    name: t({
      id: `page.register.preferredLanguage.${lang.code.toLowerCase()}`,
    }),
  }));
};

/**
 * SignUpForm Component.
 */
export const SignUpForm = () => {
  const {
    customerSignUp,
    loading,
    errors: submissionErrors,
    success,
  } = useContext(SignUpFormContext);

  const { register: registerContent } = useContext(AccountContentContext);
  const {
    customerImage,
    professionalImage,
    termsAndConditionsUrl,
    wellaBrandsUrl,
    successRedirectUrl,
  } = registerContent;

  const {
    register,
    watch,
    handleSubmit,
    trigger,
    setValue,
    formState: { errors },
  } = useForm<SignUpFormInput>({
    defaultValues: {
      billAddressIdentical: true,
      shipAddressIdentical: true,
    },
  });
  const router = useRouter();
  const locale = storeLocale(router.locale);
  const [accountType, setAccountType] = useState<'consumer' | 'pro'>(
    'consumer'
  );
  const [step, setStep] = useState<1 | 2>(1);
  const [existingUKCustomer, setExistingUKCustomer] = useState(false);
  const [dragOver, setDragOver] = useState(false);
  const [captcha, setCaptcha] = useState('');
  const [captchaError, setCaptchaError] = useState<Error | null>(null);
  const [files, setFiles] = useState<File[] | null>(null);
  const [fileError, setFileError] = useState<Error | null>(null);
  // Accepted file types.
  const acceptedFileTypes = ['application/pdf', 'image/jpg', 'image/jpeg'];
  // Max file size in bite: 1MB.
  const maxFileSize = 1000000;

  // Is site running in Switzerland country
  const isChLocale = checkIsChLocale(locale);
  const isProAccountAllowed = hasLocaleSupportForProUsers(locale);

  // Fetch countries data (includes states / zones data too).
  const { data: countries = [] } = useQuery({
    queryKey: ['countries', locale],
    queryFn: () => {
      const addressFormatter = new AddressFormatter(locale);
      return addressFormatter.getCountries();
    },
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  /**
   * Validate file(s) and reCAPTCHA fields then submit.
   * @param data - SignUpFormInput
   */
  const onSubmit: SubmitHandler<SignUpFormInput> = (data) => {
    if (
      accountType === 'pro' &&
      (!files || !files.length) &&
      locale === 'en-GB'
    ) {
      setFileError({
        message: 'File upload is required',
        name: 'UPLOAD_REQUIRED',
      });

      return;
    }

    // Check if captcha token is set and also if reCAPTCHA site key is defined.
    if (isRecaptchaEnabled() && !captcha && recaptchaSiteKey) {
      setCaptchaError({
        message: 'reCAPTCHA verification is required',
        name: 'CAPTCHA_REQUIRED',
      });

      return;
    }

    const getDateOfBirth = () => {
      if (locale === 'en-US' || locale === 'jp-JP') {
        return `1900-${data.dateOfBirth}`;
      } else {
        return `1900-${data.dateOfBirth.split('-').reverse().join('-')}`;
      }
    };

    customerSignUp({
      ...{
        ...data,
        ...(data?.dateOfBirth && {
          dateOfBirth: getDateOfBirth(),
        }),
        existingUKCustomer,
        pro: accountType === 'pro',
      },
      files,
      captcha,
    } as SignUpFormInput);

    setIsSubmitted(true);
    resetRecaptcha();
  };

  const resetRecaptcha = () => {
    if (!isRecaptchaEnabled()) return;
    setCaptcha('');
    window.grecaptcha?.reset();
  };

  /**
   * Route to account homepage when login is successful.
   */
  const isSuccessfulSubmission = isSubmitted && success;

  useEffectOnceWhen(() => {
    const redirectUrl = successRedirectUrl?.[accountType] || '/account';

    router.push(redirectUrl);
  }, isSuccessfulSubmission);

  /**
   * Get States/Zones data from selected country.
   * @param field - either 'country', 'shipCountry' or 'billCountry'
   * @returns {Zone[]}
   */
  const getSelectedCountryZones = (
    field: 'country' | 'shipCountry' | 'billCountry'
  ) => {
    // Show state dropdown just for US an CA.
    const selectedCountry = countries?.find(
      (country) =>
        country.code === watch(field) && ['US', 'CA'].includes(country.code)
    );
    return selectedCountry?.zones ?? [];
  };

  /**
   * Return list of countries based on locale.
   * @returns {Country[]}
   */
  const getCountries = () => {
    if (isChLocale)
      return CH_LOCALE_COUNTRIES.map((country) => ({
        ...country,
        name: t({ id: `page.register.country.${country.code.toLowerCase()}` }),
      }));

    if (locale === 'en-US') return countries;
    if (locale === 'fr-FR') return FR_LOCALE_COUNTRIES;
    if (locale === 'de-DE') return DE_LOCALE_COUNTRIES;
    if (locale === 'it-IT') return IT_LOCALE_COUNTRIES;
    if (locale === 'es-ES') return ES_LOCALE_COUNTRIES;
    if (locale === 'jp-JP') return JP_LOCALE_COUNTRIES;

    // Return UK list of countries.
    return GB_LOCALE_COUNTRIES;
  };

  /**
   * Return list of salon or professional types based on locale.
   * @returns {Country[]}
   */
  const getProTypes = () => {
    if (isProAccountAllowed) return PRO_TYPES;

    return SALON_TYPES;
  };

  const customerNumberField = (
    <FormField
      type="text"
      id="registerCustomerNumber"
      error={errors.customerNumber}
      loading={loading}
      required
      label={t({
        id: 'page.register.customerNumber',
        message: 'OPI UK Customer Number',
      })}
      register={() =>
        register('customerNumber', {
          required: t({
            id: 'page.register.customerNumberRequired',
            message: 'Customer Number is required',
          }),
        })
      }
    />
  );

  const firstNameField = (
    <FormField
      type="text"
      id="registerFistName"
      error={errors.firstName}
      loading={loading}
      maxLength={35}
      required
      className={styles.signupForm__halfFieldWidth}
      label={t({
        id: 'page.register.firstName',
        message: 'First Name',
      })}
      register={() =>
        register('firstName', {
          required: t({
            id: 'page.register.firstNameRequired',
            message: 'First Name is required',
          }),
          maxLength: {
            value: 35,
            message: t({
              id: 'form.errors.maxLength',
              message: `Must be less than ${() => 35} characters`,
            }),
          },
        })
      }
    />
  );

  const lastNameField = (
    <FormField
      type="text"
      id="registerLastName"
      error={errors.lastName}
      loading={loading}
      maxLength={35}
      required
      className={styles.signupForm__halfFieldWidth}
      label={t({
        id: 'page.register.lastName',
        message: 'Last Name',
      })}
      register={() =>
        register('lastName', {
          required: t({
            id: 'page.register.lastNameRequired',
            message: 'Last Name is required',
          }),
          maxLength: {
            value: 35,
            message: t({
              id: 'form.errors.maxLength',
              message: `Must be less than ${() => 35} characters`,
            }),
          },
        })
      }
    />
  );

  const emailField = (
    <FormField
      type="email"
      id="registerEmail"
      error={errors.email}
      loading={loading}
      required
      label={t({
        id: 'page.register.email',
        message: 'Email Address',
      })}
      register={() =>
        register('email', {
          required: t({
            id: 'page.register.emailRequired',
            message: 'Email is required',
          }),
        })
      }
    />
  );

  const confirmEmailField = (
    <FormField
      type="email"
      id="registerConfirmEmail"
      error={errors.confirmEmail}
      loading={loading}
      required
      label={t({
        id: 'page.register.confirmEmail',
        message: 'Confirm Email Address',
      })}
      register={() =>
        register('confirmEmail', {
          required: t({
            id: 'page.register.confirmEmailRequired',
            message: 'Confirm Email is required',
          }),
          validate: (value) => {
            if (value !== watch('email'))
              return t({
                id: 'page.register.confirmEmailDifferent',
                message: 'Must match email field',
              });
          },
        })
      }
    />
  );

  const passwordField = (
    <FormPasswordField
      id="registerPassword"
      error={errors.password}
      loading={loading}
      required
      label={t({
        id: 'page.register.password',
        message: 'Password',
      })}
      register={() =>
        register('password', {
          required: t({
            id: 'page.register.passwordRequired',
            message: 'Password is required',
          }),
        })
      }
    />
  );

  const passwordConfirmField = (
    <FormPasswordField
      id="registerConfirmPassword"
      error={errors.confirmPassword}
      loading={loading}
      required
      label={t({
        id: 'page.register.confirmPassword',
        message: 'Confirm Password',
      })}
      register={() =>
        register('confirmPassword', {
          required: t({
            id: 'page.register.confirmPasswordRequired',
            message: 'Confirm Password is required',
          }),
          validate: (value) => {
            if (value !== watch('password'))
              return t({
                id: 'page.register.confirmPasswordDifferent',
                message: 'Must match password field',
              });
          },
        })
      }
    />
  );

  const handleDateOfBirthChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length === 3 && !e.target.value.includes('-')) {
      setValue(
        'dateOfBirth',
        `${e.target.value.slice(0, 2)}-${e.target.value.slice(2)}`
      );
    }
  };

  const dobField = (
    <FormField
      type="text"
      id="registerDateOfBirth"
      error={errors.dateOfBirth}
      loading={loading}
      label={t({
        id: 'page.register.dob',
        message:
          locale === 'en-US'
            ? 'Date of Birth (MM-DD)'
            : 'Date of Birth (DD-MM)',
      })}
      maxLength={5}
      register={() =>
        register('dateOfBirth', {
          onChange: handleDateOfBirthChange,
          pattern: {
            value:
              locale === 'en-US' || locale === 'jp-JP'
                ? /((01|03|05|07|08|10|12)-(0[1-9]|1\d|2\d|3[01])|(04|06|09|11)-(0[1-9]|1\d|2\d|30)|02-(0[1-9]|1\d|2\d))/
                : /((0[1-9]|1\d|2\d|3[01])-(01|03|05|07|08|10|12)|(0[1-9]|1\d|2\d|30)-(04|06|09|11)|(0[1-9]|1\d|2\d)-02)/,
            message: t({
              id: 'page.register.dobError',
              message: 'Invalid date or format',
            }),
          },
        })
      }
    />
  );

  const proTypeSelectField = (
    <FormSelectField
      id="registerProType"
      options={getProTypes()}
      error={errors.professionalType}
      loading={loading}
      required
      label={t({
        id: 'page.register.professionalType',
        message: 'Professional type',
      })}
      register={() =>
        register('professionalType', {
          required: t({
            id: 'page.register.professionalTypeRequired',
            message: 'Professional type is required',
          }),
        })
      }
    />
  );

  const phoneField = (
    <FormField
      type="text"
      id="registerPhone"
      error={errors.phone}
      loading={loading}
      maxLength={35}
      required={locale === 'fr-FR' ? false : true}
      label={t({
        id: 'page.register.phone',
        message: 'Phone (0-000-000-0000)',
      })}
      register={() =>
        register('phone', {
          required:
            locale === 'fr-FR'
              ? false
              : t({
                  id: 'page.register.phoneRequired',
                  message: 'Phone is required',
                }),
          maxLength: {
            value: 35,
            message: t({
              id: 'form.errors.maxLength',
              message: `Must be less than ${() => 35} characters`,
            }),
          },
        })
      }
    />
  );

  const vatNumberField = (
    <FormField
      type="text"
      id="registerVatNumber"
      loading={loading}
      label={t({
        id: 'page.register.vatNumber',
        message: 'Vat Number',
      })}
      register={() => register('vatNumber')}
    />
  );

  const ltdNumberField = (
    <FormField
      type="text"
      id="registerLtdNumber"
      maxLength={10}
      loading={loading}
      label={t({
        id: 'page.register.ltdNumber',
        message: 'LTD Number',
      })}
      register={() =>
        register('ltdNumber', {
          maxLength: {
            value: 10,
            message: t({
              id: 'form.errors.maxLength',
              message: `Must be less than ${() => 10} characters`,
            }),
          },
        })
      }
    />
  );

  const licenseNumberField = (
    <FormField
      type="text"
      id="registerLicenseNumber"
      error={errors.licenseNumber}
      loading={loading}
      maxLength={10}
      required
      label={t({
        id: 'page.register.licenseNumber',
        message: 'License Number',
      })}
      register={() =>
        register('licenseNumber', {
          required: t({
            id: 'page.register.licenseNumberRequired',
            message: 'License number is required',
          }),
          maxLength: {
            value: 10,
            message: t({
              id: 'form.errors.maxLength',
              message: `Must be less than ${() => 10} characters`,
            }),
          },
        })
      }
    />
  );

  const salonField = (
    <FormField
      type="text"
      id="registerSalon"
      error={errors.salon}
      loading={loading}
      maxLength={35}
      required
      label={t({
        id: 'page.register.salon',
        message: 'Salon Name',
      })}
      register={() =>
        register('salon', {
          required: t({
            id: 'page.register.salonNameRequired',
            message: 'Salon name is required',
          }),
          maxLength: {
            value: 35,
            message: t({
              id: 'form.errors.maxLength',
              message: `Must be less than ${() => 35} characters`,
            }),
          },
        })
      }
    />
  );

  const addressField = (
    <FormField
      type="text"
      id="registerAddress"
      error={errors.address}
      loading={loading}
      maxLength={35}
      required
      label={t({
        id: 'page.register.address',
        message: 'Address',
      })}
      register={() =>
        register('address', {
          required: t({
            id: 'page.register.addressRequired',
            message: 'Address is required',
          }),
          maxLength: {
            value: 35,
            message: t({
              id: 'form.errors.maxLength',
              message: `Must be less than ${() => 35} characters`,
            }),
          },
        })
      }
    />
  );

  const shipAddressField = (
    <FormField
      type="text"
      id="registerShipAddress"
      error={errors.shipAddress}
      loading={loading}
      maxLength={35}
      required
      label={t({
        id: 'page.register.address',
        message: 'Address',
      })}
      register={() =>
        register('shipAddress', {
          required: t({
            id: 'page.register.addressRequired',
            message: 'Address is required',
          }),
          maxLength: {
            value: 35,
            message: t({
              id: 'form.errors.maxLength',
              message: `Must be less than ${() => 35} characters`,
            }),
          },
        })
      }
    />
  );

  const billAddressField = (
    <FormField
      type="text"
      id="registerBillAddress"
      error={errors.billAddress}
      loading={loading}
      maxLength={35}
      required
      label={t({
        id: 'page.register.address',
        message: 'Address',
      })}
      register={() =>
        register('billAddress', {
          required: t({
            id: 'page.register.addressRequired',
            message: 'Address is required',
          }),
          maxLength: {
            value: 35,
            message: t({
              id: 'form.errors.maxLength',
              message: `Must be less than ${() => 35} characters`,
            }),
          },
        })
      }
    />
  );

  const cityField = (
    <FormField
      type="text"
      id="registerAddress"
      error={errors.city}
      loading={loading}
      maxLength={35}
      required
      label={t({
        id: 'page.register.city',
        message: 'City',
      })}
      register={() =>
        register('city', {
          required: t({
            id: 'page.register.cityRequired',
            message: 'City is required',
          }),
          maxLength: {
            value: 35,
            message: t({
              id: 'form.errors.maxLength',
              message: `Must be less than ${() => 35} characters`,
            }),
          },
        })
      }
    />
  );

  const shipCityField = (
    <FormField
      type="text"
      id="registerShipAddress"
      error={errors.shipCity}
      loading={loading}
      maxLength={35}
      required
      label={t({
        id: 'page.register.city',
        message: 'City',
      })}
      register={() =>
        register('shipCity', {
          required: t({
            id: 'page.register.cityRequired',
            message: 'City is required',
          }),
          maxLength: {
            value: 35,
            message: t({
              id: 'form.errors.maxLength',
              message: `Must be less than ${() => 35} characters`,
            }),
          },
        })
      }
    />
  );

  const billCityField = (
    <FormField
      type="text"
      id="registerBillAddress"
      error={errors.billCity}
      loading={loading}
      required
      maxLength={35}
      label={t({
        id: 'page.register.city',
        message: 'City',
      })}
      register={() =>
        register('billCity', {
          required: t({
            id: 'page.register.cityRequired',
            message: 'City is required',
          }),
          maxLength: {
            value: 35,
            message: t({
              id: 'form.errors.maxLength',
              message: `Must be less than ${() => 35} characters`,
            }),
          },
        })
      }
    />
  );

  const stateSelectField = (
    <FormSelectField
      id="registerState"
      options={getSelectedCountryZones('country')}
      error={errors.state}
      loading={loading}
      required
      label={t({
        id: 'page.register.state',
        message: 'State',
      })}
      register={() =>
        register('state', {
          required: t({
            id: 'page.register.stateRequired',
            message: 'State is required',
          }),
        })
      }
    />
  );

  const countrySelectField = (
    <FormSelectField
      id="registerCountry"
      options={getCountries()}
      error={errors.country}
      loading={loading}
      required
      label={t({
        id: 'page.register.country',
        message: 'Country',
      })}
      register={() =>
        register('country', {
          required: t({
            id: 'page.register.countryRequired',
            message: 'Country is required',
          }),
        })
      }
    />
  );

  const preferredLanguageSelectField = (
    <FormSelectField
      id="registerPreferredLanguage"
      options={getPreferredLanguages(isChLocale)}
      error={errors.preferredLanguage}
      loading={loading}
      required
      label={t({
        id: 'page.register.preferredLanguage',
        message: 'Preferred language',
      })}
      register={() =>
        register('preferredLanguage', {
          required: t({
            id: 'page.register.preferredLanguageRequired',
            message: 'Preferred language is required',
          }),
        })
      }
    />
  );

  const shipStateSelectField = (
    <FormSelectField
      id="registerShipState"
      options={getSelectedCountryZones('shipCountry')}
      error={errors.shipState}
      loading={loading}
      required
      label={t({
        id: 'page.register.state',
        message: 'State',
      })}
      register={() =>
        register('shipState', {
          required: t({
            id: 'page.register.stateRequired',
            message: 'State is required',
          }),
        })
      }
    />
  );

  const shipCountrySelectField = (
    <FormSelectField
      id="registerShipCountry"
      options={getCountries()}
      error={errors.shipCountry}
      loading={loading}
      required
      label={t({
        id: 'page.register.country',
        message: 'Country',
      })}
      register={() =>
        register('shipCountry', {
          required: t({
            id: 'page.register.countryRequired',
            message: 'Country is required',
          }),
        })
      }
    />
  );

  const billStateSelectField = (
    <FormSelectField
      id="registerBillState"
      options={getSelectedCountryZones('billCountry')}
      error={errors.billState}
      loading={loading}
      required
      label={t({
        id: 'page.register.state',
        message: 'State',
      })}
      register={() =>
        register('billState', {
          required: t({
            id: 'page.register.stateRequired',
            message: 'State is required',
          }),
        })
      }
    />
  );

  const billCountrySelectField = (
    <FormSelectField
      id="registerBillCountry"
      options={getCountries()}
      error={errors.billCountry}
      loading={loading}
      required
      label={t({
        id: 'page.register.country',
        message: 'Country',
      })}
      register={() =>
        register('billCountry', {
          required: t({
            id: 'page.register.countryRequired',
            message: 'Country is required',
          }),
        })
      }
    />
  );

  const zipField = (
    <FormField
      type="text"
      id="registerZIP"
      error={errors.zip}
      loading={loading}
      maxLength={isChLocale ? 4 : 10}
      required={accountType === 'pro'}
      label={t({
        id: 'page.register.zip',
        message: 'Zip Code / Post Code',
      })}
      register={() =>
        register('zip', {
          required:
            accountType === 'pro'
              ? t({
                  id: 'page.register.zipRequired',
                  message: 'ZIP is required',
                })
              : false,
          validate: (value) =>
            !value ||
            postcodeValidator(value, getCountryCodeFromLocale(locale)) ||
            t({
              id: 'form.errors.invalidZip',
              message: 'Invalid ZIP code',
            }),
          maxLength: {
            value: isChLocale ? 4 : 10,
            message: t({
              id: 'form.errors.maxLength',
              message: `Must be less than ${() => 10} characters`,
            }),
          },
        })
      }
    />
  );

  const shipZipField = (
    <FormField
      type="text"
      id="registerShipZIP"
      error={errors.shipZip}
      loading={loading}
      required
      label={t({
        id: 'page.register.zip',
        message: 'Zip Code / Post Code',
      })}
      register={() =>
        register('shipZip', {
          required: t({
            id: 'page.register.zipRequired',
            message: 'ZIP is required',
          }),
        })
      }
    />
  );

  const billZipField = (
    <FormField
      type="text"
      id="registerBillZIP"
      error={errors.billZip}
      loading={loading}
      required
      label={t({
        id: 'page.register.zip',
        message: 'Zip Code / Post Code',
      })}
      register={() =>
        register('billZip', {
          required: t({
            id: 'page.register.zipRequired',
            message: 'ZIP is required',
          }),
        })
      }
    />
  );

  const marketingSection = (
    <div className={styles.signupForm__marketing}>
      <p className="text-body-small-desktop text-body-small-mobile">
        <Trans id="page.register.consent">
          We would like to keep you informed by email and other digital
          communications (including via targeted adverts online and via social
          media) about the latest offers, styling tips, trends and products from{' '}
          <a
            target="_blank"
            href={wellaBrandsUrl}
            rel="noreferrer"
            aria-label={t({
              id: 'signupForm.wellaBrandsButton',
              message: 'Wella Brands Url, opens a new tab',
            })}
          >
            Wella Brands
          </a>
          . If you would like to hear from us, please select the box below:
        </Trans>
      </p>

      <FormCheckboxField
        id="registerAcceptMarketing"
        error={errors.acceptMarketing}
        loading={loading}
        label={
          <Trans id="page.register.marketing">
            I want to receive marketing from{' '}
            <a
              target="_blank"
              href={wellaBrandsUrl}
              rel="noreferrer"
              aria-label={t({
                id: 'signupForm.wellaBrandsButton',
                message: 'Wella Brands Url, opens a new tab',
              })}
            >
              Wella Brands
            </a>
          </Trans>
        }
        register={() => register('acceptMarketing')}
      />

      <FormCheckboxField
        id="registerAcceptTerms"
        error={errors.acceptTerms}
        loading={loading}
        required
        label={
          <Trans id="page.register.termsLabel">
            I have read and accept the{' '}
            <a
              target="_blank"
              href={termsAndConditionsUrl}
              rel="noreferrer"
              aria-label={t({
                id: 'signupForm.termsAndConditionsButton',
                message: 'Terms and Conditions, opens a new tab',
              })}
            >
              Terms & Conditions
            </a>
          </Trans>
        }
        register={() =>
          register('acceptTerms', {
            required: t({
              id: 'page.register.termsRequired',
              message: 'Accepting terms & conditions is required',
            }),
          })
        }
      />
    </div>
  );

  const captchaField = (
    <Captcha
      responseCallback={(token) => {
        setCaptcha(token);
        setCaptchaError(null);
      }}
      expireCallback={() => setCaptcha('')}
      errorCallback={() => setCaptcha('')}
      renderCallback={() => {
        setCaptcha('');
        setCaptchaError(null);
      }}
      error={captchaError}
      className={styles.signupForm__captcha}
    />
  );

  const rememberMeField = (
    <FormCheckboxField
      id="registerRememberMe"
      label={t({
        id: 'page.register.rememberMe',
        message: 'Remember Me',
      })}
      loading={loading}
      register={() => register('rememberMe')}
    />
  );

  const NextStepButton = (
    <Button
      type="button"
      disabled={loading}
      onClick={async () => {
        // Validate first step fields before proceeding.
        const result = await trigger([
          'customerNumber',
          'firstName',
          'lastName',
          'email',
          'confirmEmail',
          'password',
          'confirmPassword',
        ]);

        if (result) setStep(2);
      }}
    >
      <span className="text-utility-utility-mobile text-utility-utility-desktop">
        <Trans id="page.register.nextStep">Next Step</Trans>
      </span>
    </Button>
  );

  const PrevStepButton = (
    <Button
      type="button"
      modifiers="outlined"
      disabled={loading}
      onClick={() => setStep(1)}
    >
      <span className="text-utility-utility-mobile text-utility-utility-desktop">
        <Trans id="page.register.prevStep">Back</Trans>
      </span>
    </Button>
  );

  const SubmitButton = (
    <Button type="submit" disabled={loading}>
      <span className="text-utility-utility-mobile text-utility-utility-desktop">
        <Trans id="page.register.submit">Create My Account</Trans>
      </span>
    </Button>
  );

  const GoToWellaStoreSection = (
    <div className={styles.signupForm__goToWellaStore}>
      <p className="text-body-2-desktop text-body-2-mobile">
        <Trans id="page.register.goToWellaStore">
          Find all OPI products at professional prices on Wellastore.com
        </Trans>
      </p>

      <div>
        <Button
          type="button"
          disabled={loading}
          href={
            isChLocale
              ? WELLASTORE_PROFESSIONAL_URL['create-account'][locale]
              : locale === 'de-DE'
              ? `${WELLASTORE_PROFESSIONAL_URL[locale]}`
              : `${WELLASTORE_PROFESSIONAL_URL[locale]}/create-account`
          }
        >
          <span className="text-utility-utility-mobile text-utility-utility-desktop">
            <Trans id="page.register.createWellaStoreAccount">
              Create account on Wellastore
            </Trans>
          </span>
        </Button>
      </div>
    </div>
  );

  const NotMemberSection = (
    <div className={styles.signupForm__notMember}>
      <p className="text-body-2-desktop text-body-2-mobile">
        <Trans id="page.register.alreadyMember">Already a member?</Trans>
      </p>

      <Button
        type="button"
        modifiers="text"
        disabled={loading}
        href="/account/login"
      >
        <span className="text-utility-utility-mobile text-utility-utility-desktop">
          <Trans id="page.register.login">Login in</Trans>
        </span>
      </Button>
    </div>
  );

  const FormErrors = submissionErrors && (
    <ul className={styles.signupForm__errors}>
      {submissionErrors.map((error) => {
        const capitalizedErrorMessage =
          error.message.charAt(0).toUpperCase() + error.message.slice(1);

        return (
          <li key={error.code}>
            <p
              className={clsx(
                styles.loginForm__error,
                'text-body-small-desktop text-body-small-mobile'
              )}
            >
              {capitalizedErrorMessage}
            </p>
          </li>
        );
      })}
    </ul>
  );

  /**
   * Run some validation checks on files.
   * Remove any invalid files from selection.
   * @param filesArray - files array.
   * @returns {File[]}
   */
  const filesValidation = (filesArray: File[]) => {
    const filteredTypeFiles = filesArray.filter((file) =>
      acceptedFileTypes.includes(file.type)
    );

    const filteredSizeFiles = filesArray.filter(
      (file) => file.size < maxFileSize
    );

    // Set file error only if there is a file that is not supported.
    if (filteredTypeFiles.length < filesArray.length) {
      setFileError({
        name: 'TYPE_ERROR',
        message: t({
          id: 'page.register.fileTypeError',
          message: 'We only accept jpg and pdf files',
        }),
      });

      return filteredTypeFiles;
    } else if (filteredSizeFiles.length < filesArray.length) {
      setFileError({
        name: 'SIZE_ERROR',
        message: t({
          id: 'page.register.fileSizeError',
          message: 'Max size file is 1MB',
        }),
      });
      return filteredSizeFiles;
    }

    return filesArray;
  };

  /**
   * Upload file handler.
   */
  const uploadFile = () => {
    // Clear file error.
    setFileError(null);
    const input = document.createElement('input');
    input.type = 'file';
    input.addEventListener('change', () => {
      const files = input.files;

      if (!files) return;

      let filesArray = Array.from(files);
      filesArray = filesValidation(filesArray);
      setFiles(filesArray);
    });

    input.click();
  };

  /**
   * File(s) in drop zone
   * Prevent default behavior (Prevent file from being opened)
   * set dragOver flag.
   * @param event - Drag event
   */
  const dragOverHandler = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(true);
  };

  /**
   * Prevent default behavior (Prevent file from being opened)
   * set dragOver flag.
   * @param event - Drag event
   */
  const dragLeaveHandler = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setDragOver(false);
  };

  /**
   * File(s) dropped.
   * Prevent default behavior (Prevent file from being opened)
   * Clear file error.
   * set dragOver flag.
   * set files array after validation.
   * @param event - Drag event
   */
  const dropHandler = (event: DragEvent<HTMLDivElement>) => {
    if (!event || !event.dataTransfer) return;

    event.preventDefault();

    setFileError(null);
    setDragOver(false);

    if (event.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      const dataTransferArray = Array.from(event.dataTransfer.items);
      const files = dataTransferArray
        .filter((item) => item.kind === 'file')
        .map((file) => file.getAsFile())
        .filter((file) => file) as File[];

      const filesArray = filesValidation(files);
      setFiles(filesArray);
    } else {
      // Use DataTransfer interface to access the file(s)
      let filesArray = Array.from(event.dataTransfer.files);
      filesArray = filesValidation(filesArray);
      setFiles(filesArray);
    }
  };

  /**
   * Clear errors - file & reCAPTCHA errors.
   */
  const resetForm = () => {
    setFileError(null);
    setCaptchaError(null);
  };

  const renderFormFooter = () => {
    switch (accountType) {
      case 'consumer':
        return (
          <>
            {SubmitButton}
            {NotMemberSection}
          </>
        );
      case 'pro':
        if (!isProAccountAllowed) {
          return GoToWellaStoreSection;
        }

        return (
          <>
            {NextStepButton}
            {NotMemberSection}
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Breadcrumbs
        title={t({
          id: 'breadcrumbs.accountCreate',
          message: 'Create My Account',
        })}
      />
      <div className={clsx(styles.signupForm__wrapper, 'container grid')}>
        <div
          className={clsx(
            styles.signupForm,
            'col xs4',
            step === 1 && 'l2-5',
            step === 2 && 'l2-11'
          )}
        >
          <h1
            className={clsx(
              styles.signupForm__title,
              'text-heading-h2-desktop text-heading-h2-mobile'
            )}
          >
            <Trans id="page.register.heading">Create my Account</Trans>
          </h1>

          {FormErrors}

          {step === 1 && (
            <>
              <p className="text-body-small-desktop text-body-small-mobile">
                <Trans id="page.register.selectAccountType">
                  Select the option which best applies to you:
                </Trans>
              </p>

              <div className={styles.signupForm__radioButtons}>
                <FormRadioButton
                  type="radio"
                  id="consumer_type"
                  name="account_type"
                  className="radio__input"
                  checked={accountType === 'consumer'}
                  onChange={() => {
                    resetForm();
                    setAccountType('consumer');
                  }}
                  label={t({
                    id: 'page.register.consumer',
                    message: 'Consumer',
                  })}
                />

                <FormRadioButton
                  type="radio"
                  id="pro_type"
                  name="account_type"
                  className="radio__input"
                  checked={accountType === 'pro'}
                  onChange={() => {
                    resetForm();
                    setAccountType('pro');
                  }}
                  label={t({
                    id: 'page.register.professional',
                    message: 'Professional',
                  })}
                />
              </div>
            </>
          )}

          {accountType === 'pro' && isProAccountAllowed && (
            <p
              className={clsx(
                styles.signupForm__subtitle,
                'text-body-1-bold-desktop text-body-1-bold-mobile'
              )}
            >
              {step === 1 ? (
                <Trans id="page.register.step1">Step 1 of 2</Trans>
              ) : (
                <Trans id="page.register.step2">Step 2 of 2</Trans>
              )}
            </p>
          )}

          <form
            onSubmit={handleSubmit(onSubmit)}
            className={clsx(step === 1 && styles.signupForm__el, 'grid')}
            noValidate
          >
            <div
              className={clsx(
                'col xs4',
                step === 1 && 'l1-12',
                step === 2 && 'l1-5'
              )}
            >
              {accountType === 'pro' && isProAccountAllowed && step === 2 && (
                <p
                  className={clsx(
                    styles.signupForm__subtitle,
                    'text-body-1-bold-desktop text-body-1-bold-mobile'
                  )}
                >
                  <Trans id="page.register.salonORcontactInfo">
                    Contact Information
                  </Trans>
                </p>
              )}

              {accountType === 'pro' && isProAccountAllowed && step === 1 && (
                <>
                  <p className="text-body-small-desktop text-body-small-mobile">
                    <Trans id="page.register.ukCustomer">
                      Are you an existing OPI UK Customer?
                    </Trans>
                  </p>

                  <div className={styles.signupForm__radioButtons}>
                    <FormRadioButton
                      type="radio"
                      id="yesExistingUKCustomer"
                      name="existingUKCustomer"
                      className="radio__input"
                      checked={!existingUKCustomer}
                      onChange={(event) =>
                        setExistingUKCustomer(!event.target.checked)
                      }
                      label={t({
                        id: 'page.register.noResponse',
                        message: 'No',
                      })}
                    />

                    <FormRadioButton
                      type="radio"
                      id="NoExistingUKCustomer"
                      name="existingUKCustomer"
                      className="radio__input"
                      checked={existingUKCustomer}
                      onChange={(event) =>
                        setExistingUKCustomer(event.target.checked)
                      }
                      label={t({
                        id: 'page.register.yesResponse',
                        message: 'Yes',
                      })}
                    />
                  </div>
                </>
              )}

              <div className="form-group">
                {step === 1 && existingUKCustomer && customerNumberField}

                {step === 1 &&
                  (isProAccountAllowed || accountType !== 'pro') &&
                  [
                    firstNameField,
                    lastNameField,
                    emailField,
                    confirmEmailField,
                    passwordField,
                    passwordConfirmField,
                  ].map((field, index) => (
                    <React.Fragment key={index}>{field}</React.Fragment>
                  ))}

                {step === 2 &&
                  [
                    salonField,
                    addressField,
                    cityField,
                    countrySelectField,
                    stateSelectField,
                    zipField,
                    locale === 'en-GB' && [
                      phoneField,
                      vatNumberField,
                      ltdNumberField,
                    ],
                    locale === 'en-US' && licenseNumberField,
                    !localesWithoutProTypeSelectForProUsers &&
                      proTypeSelectField,
                  ]
                    .flat()
                    .map((field, index) => (
                      <React.Fragment key={index}>{field}</React.Fragment>
                    ))}

                {accountType === 'consumer' && dobField}
                {accountType === 'consumer' && countrySelectField}
                {accountType === 'consumer' && preferredLanguageSelectField}
                {accountType === 'consumer' && stateSelectField}
                {accountType === 'consumer' && zipField}
                {locale === 'fr-FR' && accountType === 'consumer' && phoneField}

                {step === 2 && (
                  <>
                    <FormCheckboxField
                      id="registerShipAddressIdentical"
                      label={t({
                        id: 'page.register.shipAddressIdentical',
                        message:
                          'My ship to address information is the same as my salon information',
                      })}
                      loading={loading}
                      className="spanAll"
                      register={() => register('shipAddressIdentical')}
                    />

                    {!watch('shipAddressIdentical') &&
                      [
                        shipAddressField,
                        shipCityField,
                        shipCountrySelectField,
                        shipStateSelectField,
                        shipZipField,
                      ].map((field, index) => (
                        <React.Fragment key={index}>{field}</React.Fragment>
                      ))}

                    {locale === 'en-GB' && (
                      <>
                        <FormCheckboxField
                          id="registerBillAddressIdentical"
                          label={t({
                            id: 'page.register.billAddressIdentical',
                            message:
                              'My bill to address information is the same as my salon information',
                          })}
                          loading={loading}
                          className="spanAll"
                          register={() => register('billAddressIdentical')}
                        />

                        {!watch('billAddressIdentical') &&
                          [
                            billAddressField,
                            billCityField,
                            billCountrySelectField,
                            billStateSelectField,
                            billZipField,
                          ].map((field, index) => (
                            <React.Fragment key={index}>{field}</React.Fragment>
                          ))}
                      </>
                    )}
                  </>
                )}
              </div>

              {accountType === 'consumer' && marketingSection}
              {accountType === 'consumer' && captchaField}
              {accountType === 'consumer' && rememberMeField}

              {step === 1 && (
                <div
                  className={clsx({
                    [styles.signupForm__footer]: true,
                    [styles.signupForm__footer__DE]: locale === 'de-DE', // DE text is too long so we need to flex-wrap
                  })}
                >
                  {renderFormFooter()}
                </div>
              )}
            </div>

            {accountType === 'pro' && step === 2 && (
              <div className="col l7-12 xs4">
                {locale === 'en-GB' ? (
                  <>
                    <p
                      className={clsx(
                        styles.signupForm__subtitle,
                        'text-body-1-bold-desktop text-body-1-bold-mobile'
                      )}
                    >
                      <Trans id="page.register.qualifications">
                        Qualifications
                      </Trans>
                    </p>
                    <p className="text-body-small-desktop text-body-small-mobile">
                      <Trans id="page.register.attachFiles">
                        You must attach either proof of Public Liability
                        Insurance Certificate or proof of Student ID. If neither
                        are attached then your application will not be
                        processed.
                      </Trans>
                    </p>

                    <div
                      className={clsx(
                        styles.signupForm__upload,
                        dragOver && styles['signupForm__upload--active']
                      )}
                      onDragOver={dragOverHandler}
                      onDragLeave={dragLeaveHandler}
                      onDrop={dropHandler}
                    >
                      <p className="text-body-1-desktop text-body-1-mobile">
                        <Trans id="page.register.uploadLabel">
                          Please upload your certificates.
                        </Trans>
                      </p>
                      <p className="text-body-2-desktop text-body-2-mobile">
                        <Trans id="page.register.dragDropLabel">
                          Drag & Drop files here or
                        </Trans>
                      </p>

                      <Button
                        type="button"
                        disabled={loading}
                        onClick={uploadFile}
                      >
                        <span className="text-utility-utility-mobile text-utility-utility-desktop">
                          <Trans id="page.register.browseFiles">
                            Browse Files
                          </Trans>
                        </span>
                      </Button>
                    </div>

                    {fileError && (
                      <p
                        className={clsx(
                          styles.signupForm__error,
                          'text-body-small-desktop text-body-small-mobile'
                        )}
                      >
                        {fileError.message}
                      </p>
                    )}

                    {files &&
                      files.length > 0 &&
                      files.map((file, index) => (
                        <div key={index} className={styles.signupForm__file}>
                          <IconFile />
                          <p>{file.name}</p>
                        </div>
                      ))}

                    <p
                      className={clsx(
                        styles.signupForm__subtitle,
                        'text-body-1-bold-desktop text-body-1-bold-mobile'
                      )}
                    >
                      <Trans id="page.register.qualificationProof">
                        I’ve uploaded proof of:
                      </Trans>
                    </p>

                    <p
                      className={clsx(
                        'text-body-small-desktop text-body-small-mobile',
                        (errors.publicLiabilityInsurance ||
                          errors.studentProofEnrollment) &&
                          styles.signupForm__error
                      )}
                    >
                      <Trans id="page.register.qualificationType">
                        Please check at least one option:
                      </Trans>
                    </p>

                    <FormCheckboxField
                      id="registerPublicLiabilityInsurance"
                      label="Public Liability Insurance Certificate"
                      loading={loading}
                      register={() =>
                        register('publicLiabilityInsurance', {
                          validate: (value) =>
                            value || watch('studentProofEnrollment'),
                        })
                      }
                    />

                    <FormCheckboxField
                      id="registerStudentProofEnrollment"
                      label="Student Proof of Enrollment"
                      loading={loading}
                      register={() =>
                        register('studentProofEnrollment', {
                          validate: (value) =>
                            value || watch('publicLiabilityInsurance'),
                        })
                      }
                    />
                  </>
                ) : (
                  <>
                    {!localesWithoutInterestFormForProUsers.includes(
                      locale
                    ) && (
                      <>
                        <p
                          className={clsx(
                            styles.signupForm__subtitle,
                            'text-body-1-bold-desktop text-body-1-bold-mobile'
                          )}
                        >
                          <Trans id="page.register.interests">
                            I'm interested in:
                          </Trans>
                        </p>
                        <div className={styles.signupForm__interests}>
                          {/**
                           * Note: any changes to the checkboxes here also needs
                           * reflecting in the `create.ts` API route.
                           * - API route has hard-coded strings that map to these
                           *   strings.
                           *  - A future refactor would be to handle all interests
                           *    into an array on the front-end to remove the need
                           *    for any mapping on the backend.
                           * - e.g.: https://codesandbox.io/s/react-hook-form-array-of-checkboxes-p58m8?file=/src/app.tsx
                           */}
                          <FormCheckboxField
                            id="registerOpiNailCourses"
                            label={t({
                              id: 'page.register.interest1',
                              message: 'OPI Nail Education',
                            })}
                            loading={loading}
                            register={() => register('interest1')}
                          />
                          <FormCheckboxField
                            id="registerSystems"
                            label={t({
                              id: 'page.register.interest2',
                              message: 'Distributors Near Me',
                            })}
                            loading={loading}
                            register={() => register('interest2')}
                          />
                          <FormCheckboxField
                            id="registerNewProducts"
                            label={t({
                              id: 'page.register.interest3',
                              message:
                                'Systems (GelColor, Powder Perfection, and more)',
                            })}
                            loading={loading}
                            register={() => register('interest3')}
                          />

                          <FormCheckboxField
                            id="registerGrowingBusiness"
                            label={t({
                              id: 'page.register.interest4',
                              message: 'FAQs',
                            })}
                            loading={loading}
                            register={() => register('interest4')}
                          />
                          <FormCheckboxField
                            id="registerNearbyDistributors"
                            label={t({
                              id: 'page.register.interest5',
                              message: 'New Products',
                            })}
                            loading={loading}
                            register={() => register('interest5')}
                          />

                          <FormCheckboxField
                            id="registerFaqs"
                            label={t({
                              id: 'page.register.interest6',
                              message: 'Becoming an Educator',
                            })}
                            loading={loading}
                            register={() => register('interest6')}
                          />
                          <FormCheckboxField
                            id="registerIngredientsSDS"
                            label={t({
                              id: 'page.register.interest7',
                              message: 'Growing My Biz',
                            })}
                            loading={loading}
                            register={() => register('interest7')}
                          />
                        </div>
                      </>
                    )}
                  </>
                )}

                {marketingSection}
                {captchaField}

                <div className={styles.signupForm__footer}>
                  {PrevStepButton}
                  {SubmitButton}
                </div>
              </div>
            )}
          </form>
        </div>

        {step === 1 && (
          <div className={clsx(styles.signupForm__image, 'col l7-11 xs4')}>
            {accountType === 'consumer' && customerImage?.url && (
              <Image
                src={customerImage.url}
                alt={customerImage.title}
                title={customerImage.title}
                fill
                sizes="(max-width: 1024px) 100vw, 522px"
                placeholder="blur"
                blurDataURL={`data:image/svg+xml;base64,${toBase64(
                  Shimmer(customerImage.width, customerImage.height)
                )}`}
              />
            )}

            {accountType === 'pro' && professionalImage?.url && (
              <Image
                src={professionalImage.url}
                alt={professionalImage.title}
                title={professionalImage.title}
                fill
                sizes="(max-width: 1024px) 100vw, 522px"
                placeholder="blur"
                blurDataURL={`data:image/svg+xml;base64,${toBase64(
                  Shimmer(professionalImage.width, professionalImage.height)
                )}`}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default SignUpForm;
