import { useSelector } from '@xstate/react';
import type { ReactNode } from 'react';
import React from 'react';
import { createContext, useContext } from 'use-context-selector';
import type { StoreState } from 'components/storeContext/StoreContext';
import { StoreContext } from 'components/storeContext/StoreContext';
import type { Maybe, StoreError } from 'components/storeContext/storeMachine';
import { sendDataToGTM } from 'utils/gtm/gtm';
import type { SignUpFormInput } from './SignUpForm';

type ContextType = {
  customerSignUp(payload: SignUpFormInput): void;
  customerSignInSuccess(): void;
  loading: boolean;
  errors: Maybe<StoreError[]>;
  success: boolean;
};

export const SignUpFormContext = createContext<ContextType>(
  // Typed this way to avoid TS errors,
  // looks odd I know
  {
    customerSignUp: () => {
      return;
    },
    customerSignInSuccess: () => {
      return;
    },
    loading: false,
    errors: null,
    success: false,
  }
);

const selectLoadingState = (state: StoreState) =>
  state.matches('customerMachine.fetching');

const selectSignUpErrors = (state: StoreState) =>
  state.context.customerErrors?.CUSTOMER_ERROR ?? null;

const selectSuccessState = (state: StoreState) =>
  state.matches('customerMachine.signedIn.success') ||
  state.matches('customerMachine.signedIn.active');

export const SignUpFormProvider = ({ children }: { children: ReactNode }) => {
  const { storeService } = useContext(StoreContext);

  const loading = useSelector(storeService, selectLoadingState);

  const success = useSelector(storeService, selectSuccessState);

  const errors = useSelector(storeService, selectSignUpErrors);

  const customerSignUp = (payload: SignUpFormInput) => {
    storeService.send({
      type: 'CUSTOMER_SIGN_UP',
      payload,
    });
  };

  const customerSignInSuccess = () => {
    storeService.send('CUSTOMER_SIGN_IN_SUCCESS');
    sendDataToGTM({ event: 'account-signup' });
  };

  return (
    <SignUpFormContext.Provider
      value={{
        customerSignUp,
        customerSignInSuccess,
        loading,
        errors,
        success,
      }}
    >
      {children}
    </SignUpFormContext.Provider>
  );
};
