import { Trans, t } from '@lingui/macro';
import type { Country, Zone } from '@shopify/address-consts';
import clsx from 'clsx';
import type {
  InputHTMLAttributes,
  ReactNode,
  FocusEvent,
  ChangeEvent,
} from 'react';
import { useEffect, useRef, useState } from 'react';
import type { FieldError, UseFormRegisterReturn } from 'react-hook-form';
import styles from './SignUpForm.module.scss';

/**
 * Form Field.
 */
export const FormField = ({
  type,
  id,
  error,
  label,
  required = false,
  className,
  loading = false,
  register,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & {
  type: string;
  id: string;
  error?: FieldError;
  label: string;
  required?: boolean;
  className?: string;
  loading?: boolean;
  register: () => UseFormRegisterReturn;
}) => {
  const additionalAttributes =
    type === 'date'
      ? {
          /**
           * Adding data-valid attribute for date inputs on blur event.
           * this is needed to help with floating label styles.
           * Not adding class as classes are reset on error.
           * :valid pseudo selector do not work unless the input is required.
           * @param event - FocusEvent
           */
          onBlur: (event: FocusEvent<HTMLInputElement>) => {
            if (!event?.currentTarget?.value) {
              event.currentTarget.removeAttribute('data-valid');
              return;
            }

            event.currentTarget.setAttribute('data-valid', '');
          },
        }
      : {};

  return (
    <div className={clsx('form-group__field', className)}>
      <input
        type={type}
        id={id}
        placeholder={label}
        className={clsx(
          'text-body-2-desktop text-body-2-mobile',
          error && 'input-error'
        )}
        disabled={loading}
        required={required}
        {...props}
        {...register()}
        {...additionalAttributes}
      />

      <label
        htmlFor={id}
        className={clsx(
          'form-group__label text-body-2-desktop text-body-2-mobile',
          required && 'form-group__label--required'
        )}
      >
        {label}
      </label>

      {error && (
        <p
          className={clsx(
            styles.signupForm__error,
            'text-body-small-desktop text-body-small-mobile'
          )}
        >
          {error.message}
        </p>
      )}
    </div>
  );
};

/**
 * Form TextArea Field.
 */
export const FormTextAreaField = ({
  id,
  error,
  label,
  required = false,
  className,
  loading = false,
  register,
  ...props
}: InputHTMLAttributes<HTMLTextAreaElement> & {
  id: string;
  error?: FieldError;
  label: string;
  required?: boolean;
  className?: string;
  loading?: boolean;
  register: () => UseFormRegisterReturn;
}) => {
  const { maxLength = 0 } = props;
  const { onChange, ...registerWithoutOnChange } = register();
  const [value, setValue] = useState('');

  const changeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event?.currentTarget?.value || '');
    onChange(event);
  };

  return (
    <div className={clsx('form-group__field', className)}>
      <textarea
        id={id}
        placeholder={label}
        className={clsx(
          'text-body-2-desktop text-body-2-mobile',
          error && 'input-error'
        )}
        disabled={loading}
        required={required}
        rows={10}
        onChange={changeHandler}
        {...props}
        {...registerWithoutOnChange}
      />

      <label
        htmlFor={id}
        className={clsx(
          'form-group__label text-body-2-desktop text-body-2-mobile',
          required && 'form-group__label--required'
        )}
      >
        {label}
      </label>

      {error && (
        <p
          className={clsx(
            styles.signupForm__error,
            'text-body-small-desktop text-body-small-mobile'
          )}
        >
          {error.message}
        </p>
      )}

      {maxLength > 0 && (
        <span
          className={clsx(
            styles.signupForm__maxLength,
            'text-body-caption-desktop'
          )}
        >
          {value.length}/{maxLength}
        </span>
      )}
    </div>
  );
};

/**
 * Form Select Field.
 */
export const FormSelectField = ({
  id,
  options,
  error,
  label,
  required = false,
  loading = false,
  labelClass = '',
  register,
}: {
  id: string;
  options: Country[] | Zone[];
  error?: FieldError;
  label: string;
  required?: boolean;
  loading?: boolean;
  labelClass?: string;
  register: () => UseFormRegisterReturn;
}) => {
  if (!options.length) return null;

  return (
    <div className="form-group__field">
      <select
        id={id}
        className={clsx(
          'text-body-2-desktop text-body-2-mobile',
          error && 'input-error'
        )}
        defaultValue=""
        required={required}
        {...register()}
        disabled={loading}
      >
        <option disabled value=""></option>
        {options.map((option) => (
          <option key={option.code} value={option.code}>
            {option.name}
          </option>
        ))}
      </select>

      <label
        htmlFor={id}
        className={clsx(
          'form-group__label text-body-2-desktop text-body-2-mobile',
          required && 'form-group__label--required',
          labelClass
        )}
      >
        {label}
      </label>

      {error && (
        <p
          className={clsx(
            styles.signupForm__error,
            'text-body-small-desktop text-body-small-mobile'
          )}
        >
          {error.message}
        </p>
      )}
    </div>
  );
};

/**
 * Form Checkbox Button.
 */
export const FormCheckboxField = ({
  id,
  error,
  loading = false,
  required = false,
  label,
  className,
  register,
}: {
  id: string;
  error?: FieldError;
  loading?: boolean;
  required?: boolean;
  label: ReactNode | string;
  className?: string;
  register: () => UseFormRegisterReturn;
}) => {
  return (
    <div className={clsx(styles.signupForm__checkbox, 'checkbox', className)}>
      <input
        type="checkbox"
        id={id}
        className="checkbox__input"
        {...register()}
        disabled={loading}
      />
      <label
        className="checkbox__label text-body-2-desktop text-body-2-mobile"
        htmlFor={id}
      >
        {label}
        {required && <span className="checkbox__required"> *</span>}
      </label>

      {error && (
        <p
          className={clsx(
            styles.signupForm__error,
            'text-body-small-desktop text-body-small-mobile'
          )}
        >
          {error.message}
        </p>
      )}
    </div>
  );
};

/**
 * Form Radio Button.
 */
export const FormRadioButton = ({
  label,
  register,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  register?: () => UseFormRegisterReturn;
}) => {
  const { id } = props;
  return (
    <div className="radio">
      {register ? <input {...props} {...register()} /> : <input {...props} />}
      <label
        className="radio__label text-body-small-desktop text-body-small-mobile"
        htmlFor={id}
      >
        {label}
      </label>
    </div>
  );
};

/**
 * Form Password Field
 * - Includes button for show/hide password
 * - Password is always hidden on form submit
 */
export const FormPasswordField = ({
  id,
  error,
  label,
  required = false,
  className,
  loading = false,
  register,
  ...props
}: InputHTMLAttributes<HTMLInputElement> & {
  id: string;
  error?: FieldError;
  label: string;
  required?: boolean;
  className?: string;
  loading?: boolean;
  register: () => UseFormRegisterReturn;
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const passwordToggle = useRef<HTMLButtonElement>(null);

  const toggleType = () => {
    setShowPassword(!showPassword);
  };

  /**
   * When a parent form is submitted, hide the password again.
   */
  const handleFormSubmit = () => {
    setShowPassword(false);
  };

  /**
   * Set an event listener for any parent form element's submissions.
   */
  useEffect(() => {
    if (!passwordToggle.current) return;
    const form = passwordToggle.current.closest('form');

    if (form) {
      form.addEventListener('submit', handleFormSubmit);
    }

    return () => {
      form?.removeEventListener('submit', handleFormSubmit);
    };
  }, []);

  return (
    <div className={clsx('form-group__field', className)}>
      <div className="form-group__password-wrapper">
        <input
          type={showPassword ? 'text' : 'password'}
          id={id}
          placeholder={label}
          className={clsx(
            'text-body-2-desktop text-body-2-mobile',
            error && 'input-error'
          )}
          disabled={loading}
          required={required}
          {...props}
          {...register()}
        />

        <label
          htmlFor={id}
          className={clsx(
            'form-group__label text-body-2-desktop text-body-2-mobile',
            required && 'form-group__label--required'
          )}
        >
          {label}
        </label>

        <button
          className={clsx(
            'form-group__password-toggle',
            error && 'input-error'
          )}
          type="button"
          ref={passwordToggle}
          onClick={toggleType}
          aria-controls={id}
          disabled={loading}
          aria-label={
            showPassword
              ? t({
                  id: 'accessibility.password.hide',
                  message: 'Hide password',
                })
              : t({
                  id: 'accessibility.password.show',
                  message: 'Show password',
                })
          }
        >
          <span
            className="
            form-group__password-toggle-text
            text-body-small-mobile
            text-body-caption-desktop"
          >
            {showPassword ? (
              <Trans id="password.hide">Hide</Trans>
            ) : (
              <Trans id="password.show">Show</Trans>
            )}
          </span>
        </button>

        <span className="visually-hidden" aria-hidden="true">
          {showPassword ? (
            <Trans id="accessibility.password.shownMessage">
              Your password is shown
            </Trans>
          ) : (
            <Trans id="accessibility.password.hiddenMessage">
              Your password is hidden
            </Trans>
          )}
        </span>
      </div>

      {error && (
        <p
          className={clsx(
            styles.signupForm__error,
            'text-body-small-desktop text-body-small-mobile'
          )}
        >
          {error.message}
        </p>
      )}
    </div>
  );
};
